<template>
    <div class="c-app flex-row align-items-center" :style="{backgroundImage: 'url('+organization_background+')', backgroundSize: 'cover','box-shadow': 'inset 0 0 0 1000px rgba(108, 122, 137, 0.35)' }" >
        <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
        <CContainer >
            <CRow class="justify-content-center">
                <CCol md="4">
                    <div class="invalid-organization" v-if="!organization_exists">
                        Invalid Organization. <br>Please ask your system Administrator.
                    </div>
                    <br>
                    <CCard class="p-4 login-panel" style="border-radius:20px;box-shadow: 0px 5px 80px 0px;" v-if="!expired_link">
                        <CForm @submit.prevent="resetPassword">
                            <center>
                                <font-awesome-icon size="2xl" icon="user-lock"/>
                            <hr>
                            <h4>Reset Password</h4>
                            </center>
                            <hr>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.credentials.new_password.$model"
                                :isValid="checkIfValidPassword('new_password')"
                                placeholder="New Password" 
                                autocomplete="off" 
                                :type="password_text" 
                                v-model="credentials.new_password"
                                invalidFeedback="Required password containing at least: number, uppercase and lowercase letter, 8 characters"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                                <template #append>
                                    <CButton
                                        color="primary"
                                        @click="password_view = password_view === false ? true : false, showPassword()"
                                    >
                                        <font-awesome-icon :icon="!password_view ? 'eye-slash' : 'eye'"/>
                                    </CButton>
                                </template>
                            </CInput>
                            <CInput 
                                :lazy="false"
                                :value.sync="$v.credentials.new_confirm_password.$model"
                                :isValid="checkIfValidPassword('new_confirm_password')"
                                placeholder="Confirm New Password" 
                                autocomplete="off" 
                                :type="password_text" 
                                v-model="credentials.new_confirm_password"
                                invalidFeedback="New and confirm password must match"
                            >
                                <template #prepend-content>
                                    <font-awesome-icon icon="lock"/>
                                </template>
                            </CInput>
                            <CRow v-if="organization_exists">
                                 <CCol col="6" class="text-leftt" >
                                    <router-link :to="{path: `/`}">
                                        <CButton
                                            color="secondary" 
                                        > 
                                            <font-awesome-icon icon="arrow-left"/> Cancel
                                        </CButton>
                                    </router-link>
                                </CCol>
                                <CCol col="6" class="text-right" >
                                    <CButton 
                                        block
                                        :disabled="!isValidPassword || !submit_btn" 
                                        type="submit" 
                                        color="primary" 
                                    >
                                        <font-awesome-icon 
                                            :icon="submit_btn ? 'sign-in-alt' : 'circle-notch'"
                                            :pulse="!submit_btn"
                                        /> Update Password
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCard>
                    <CCard class="p-4 login-panel" style="border-radius:20px;box-shadow: 0px 5px 80px 0px;" v-if="expired_link">
                        <center>
                                <font-awesome-icon size="2xl" icon="user-lock"/>
                            <hr>
                            <h4>Your password reset link has expired.</h4>
                            <p class="text-muted">The password reset was requested more than 30 minutes ago. If you still need to reset your password, you can request a new reset password.</p>
                        <CRow v-if="organization_exists">
                        <CCol>
                            <router-link :to="{path: `/${organization_name}/account-recovery`}">
                                <CButton
                                    block
                                    color="primary" 
                                > 
                                 Request a new reset password
                                </CButton>
                            </router-link>
                        </CCol>
                    </CRow>
                        </center>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>
<script>
import { required, sameAs, minLength, helpers} from "vuelidate/lib/validators"
export default {
    name: "ResetPassword",
    data() {
        return {
            organization_name: this.$route.params.orgName,
            organization_key: null,
            organization_logo: null,
            organization_background: null,
            organization_exists: true,
            credentials: {
                user_id: this.$route.params.id,
                new_password: null,
                new_confirm_password: null,
            },
            password_view: false,
            password_text: 'password',
            submit_btn: true,
            expired_link: false,
        };
    },
    computed: {
        isValidPassword () { return !this.$v.credentials.$invalid },
        isDirtyPassword () { return this.$v.credentials.$anyDirty },
    },
    validations: { // FORM Validation (Vue)
        credentials: {
            new_password: {required, minLength: minLength(8), strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/) },
            new_confirm_password: {required, sameAsPassword: sameAs('new_password') },
        },
    },
    created() {
        this.getOrganization();
        this.validatePassword();
        document.title = "Reset Password"
    },
    methods: {
        getOrganization: function () {
            this.$Progress.start()
            axios.get(process.env.VUE_APP_BACKEND_URL+'/organization/'+this.organization_name, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.organization_key = response.data.data.org_key
                    this.$store.commit('SET_ORGANIZATION_LOGO', this.$backend_url+"/organization/images/logo/"+response.data.data.org_key);
                    this.organization_logo = this.$store.getters.getOrganizationLogo
                    this.organization_background = this.$backend_url+"/organization/images/background_image/"+response.data.data.org_key
                    this.$store.commit("SET_ORGANIZATION_NAME", response.data.data.org_name);
                    this.$store.commit("SET_ORGANIZATION_KEY", response.data.data.org_key);
                } else {
                    this.organization_exists = false;
                }
                this.checkLinkExpiration();
            })
        },
        checkLinkExpiration: function () {
            window.axios.defaults.headers.common['Organization-Name'] = this.$store.getters.getOrganizationName
            window.axios.defaults.headers.common['Organization-Key'] = this.$store.getters.getOrganizationKey
            let data = {
                user_id: this.credentials.user_id,
                org_name: this.organization_name,
            };
            axios.post(process.env.VUE_APP_BACKEND_URL+"/reset-password-expiration", data, {validateStatus: () => true})
                .then((response) => {
                    if (response.status == 200) {
                        this.expired_link = response.data.data
                    }
                    this.$Progress.finish()
                })
        },
        validatePassword: function () {
            this.$v.credentials.$touch()
        },
        checkIfValidPassword: function (fieldName) {
            const field = this.$v.credentials[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validatePasswordInput: function() {
            if(
                !this.credentials.new_password ||
                !this.credentials.new_confirm_password
                ) return false;
            return true;
        },
        showPassword: function() {
            if(this.password_view) {
                this.password_text = 'text'
            } else {
                this.password_text = 'password'
            }
        },
        resetPassword: function () {
            this.$Progress.start()
            let data = {
                user_id: this.credentials.user_id,
                new_password: this.credentials.new_password,
                new_confirm_password: this.credentials.new_confirm_password
            };
            this.submit_btn = false
            if (this.validatePasswordInput()) {
                axios.post(process.env.VUE_APP_BACKEND_URL+"/reset-password", data, {validateStatus: () => true})
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: 'center',
                            icon: "success",
                            title: "Password has been reset successfully.",
                            timer: 3000,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        }).then(() => {
                            this.$router.push({ path: '/'+this.organization_name.toLowerCase()+'/login' })
                        })
                        return;
                    }
                    this.submit_btn = true
                })
            } else {
                this.$swal({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    timerProgressBar: true,
                })
            }
            this.$Progress.finish()
        }
        
    },
    watch: {
    }
}
</script>